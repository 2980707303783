/* eslint-disable no-bitwise */
/// <reference path="../data/signalr-bp.ts" />
// Them
import React, {
  useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import ForumIcon from '@mui/icons-material/Forum';

// Us
import ProfileMenu from '@components/layout/ProfileMenu';
import ContentList from '@components/layout/ContentList';
import { useAppDispatch } from '@store/hooks';
import {
  storeSystemFolderState, useContentList, useIncludeSystemFolders,
} from '@store/contentListSlice';
import { useUserProfile } from '@store/userProfileSlice';
import { useWorkspaces } from '@store/workspacesSlice';
import { useProduct } from '@store/productSlice';
import { reactPath } from '@components/utilities/Paths';
import { ContentListWidgetStateContext } from '@components/layout/ContentListWidgetState';
import { CustomTheme } from '@components/layout/Theme';
import { GeneralUXContext } from '@components/layout/GeneralUXContext';
import { getUserName } from '@components/data/modelHelpers';
import { DefaultTheme } from '@mui/system';
import { StyledAvatarButton } from './StyledButtons';
import TopNav from './TopNav';
import WorkspaceBar from './WorkspaceBar';
import Message from './Message';

interface StyleContext {
  chatbotEnabled: boolean;
}

const useStyles = makeStyles<DefaultTheme, StyleContext>((customTheme: CustomTheme) => ({
  root: {
    display: 'flex',
  },
  appBarChild: {
    flex: '1 1 0%',
    display: 'grid',
    gridTemplateColumns: (ctx: StyleContext) => (ctx.chatbotEnabled ? '120px 440px 1fr 45px 65px' : '120px 440px 1fr 65px'),
  },
  appBarLogo: {
    margin: '.5rem',
    maxHeight: '44px',
    maxWidth: '105px',
  },
  appBarMenu: {
    display: 'flex',
    marginLeft: '1rem',
  },
  appBarMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minWidth: 0,
  },
  appBarMessage: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  appBarAvatars: {
    justifySelf: 'end',
  },
}));

export default function Layout() {
  const { setOpenChatbot } = useContext(GeneralUXContext);
  const dispatch = useAppDispatch();
  const contentList = useContentList();
  const workspaces = useWorkspaces();
  const includeSystemFolders = useIncludeSystemFolders();
  const userProfile = useUserProfile();
  const history = useHistory();
  const location = useLocation();
  const product = useProduct();
  const classes = useStyles({ chatbotEnabled: product?.chatbotEnabled });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    if (contentList?.length && location.pathname === '/') {
      history.replace(`/if${contentList[0].url}`);
    }
  }, [location, history, contentList]);
  const handleUserAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
  const handleProfileMenuClose = () => { setAnchorEl(null); };

  const toggleSystemFolders = () => {
    dispatch(storeSystemFolderState(!includeSystemFolders));
  };

  const {
    open, pinned, setOpen,
  } = useContext(ContentListWidgetStateContext);

  const handleContentListClick = () => {
    if (!pinned) {
      setOpen(!open);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: product.altBannerColor }}
      >
        <div className={classes.appBarChild}>
          {product && (
            <a href={product.logoLink || '#'} target="_blank" rel="noopener noreferrer">
              <img
                src={product.logoURL || `${reactPath}/bp-logix-logo.svg`}
                alt="BP Logix"
                className={classes.appBarLogo}
              />
            </a>
          )}
          <TopNav
            className={classes.appBarMenu}
            workspaces={workspaces}
            contentListToggleState={open && !pinned}
            onContentListClick={handleContentListClick}
            userProfile={userProfile}
          />
          <Message
            containerClassName={classes.appBarMessageContainer}
            textClassName={classes.appBarMessage}
            MsgText={product.envMsgText || ''}
            tooltipColor={product.altBannerColor}
          />
          {product?.chatbotEnabled && (
            <>
              <StyledAvatarButton
                classes={{ root: classes.appBarAvatars }}
                onClick={() => setOpenChatbot(true)}
                sx={{
                  width: '32px !important', height: '32px !important', borderRadius: '50%', backgroundColor: '#528bbc',
                }}
                data-cy="PathfinderMenuButton"
              >
                <Tooltip title="Ask Pathfinder for help">
                  <Avatar alt="?" sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>
                    <ForumIcon />
                  </Avatar>
                </Tooltip>
              </StyledAvatarButton>
            </>
          )}
          {userProfile ? (
            <>
              <StyledAvatarButton
                classes={{ root: classes.appBarAvatars }}
                onClick={handleUserAvatarClick}
                id="UserProfile"
                data-cy="ProfileMenuButton"
              >
                <Tooltip title={getUserName(userProfile)}>
                  <Avatar
                    alt={getUserName(userProfile)}
                    src={userProfile.imageUrl}
                  />
                </Tooltip>
              </StyledAvatarButton>
              <ProfileMenu anchorEl={anchorEl} open={!!anchorEl} onClose={handleProfileMenuClose} userProfile={userProfile} />
            </>
          ) : (<Skeleton variant="circular" width={40} height={40} />)}
        </div>
        <WorkspaceBar workspaces={workspaces} />
      </AppBar>
      <div>
        <ContentList
          contentList={contentList}
          includeSystemFolders={includeSystemFolders}
          toggleSystemFolders={toggleSystemFolders}
        />
      </div>
    </>
  );
}
