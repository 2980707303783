/* eslint-disable no-bitwise */
import React from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CustomTheme } from '@components/layout/Theme';

// Us
import { setActiveWorkspaceAsync, useWorkspaces } from '@store/workspacesSlice';

const StyledClose = styled(Close)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    ...theme.typography.button,
    display: 'flex',
    padding: '2px',
    borderRadius: '10px',
    color: theme.palette.common.black,
    transition: '0.2s',
  },
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: '#a1a1a1',
  },
}));

interface OwnProps {
  activeCaseId?: string;
  onClose?(): any;
}

export default function CaseCloser(props: React.PropsWithChildren<OwnProps>) {
  const { activeCaseId, onClose } = props;
  const dispatch = useDispatch();

  const workspaces = useWorkspaces();
  let workspace = workspaces.find((ws) => ws.isActive);
  // If on a case workspace or no workspace, switch back to the previous workspace from sessionStorage when exiting the case.
  if (workspace?.isOpenCase || !workspace) {
    workspace = workspaces.find((ws) => ws.id === sessionStorage.getItem('lastWorkspaceID'));
    if (!workspace) {
      workspace = workspaces.length > 0 ? workspaces[0] : undefined;
    }
  }

  const leaveCase = () => {
    setActiveWorkspaceAsync(dispatch, workspace?.id ?? '');
    onClose?.();
  };

  return (
    <Link to={`/if/workspace.aspx?CLOSECASE=${activeCaseId}`} onClick={leaveCase} style={{ textDecoration: 'none', color: 'inherit' }}>
      <StyledClose />
    </Link>
  );
}
