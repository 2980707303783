// Them
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ReactMarkdown from 'react-markdown';

// Us
import { reactPathTS } from '@components/utilities/Paths';
import { useUserProfile } from '@store/userProfileSlice';
import { getUserName } from '@components/data/modelHelpers';

const useStyles = makeStyles(() => ({
  bubbleContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  bubble: {
    maxWidth: '70%',
    padding: '15px 15px',
    margin: 5,
    borderRadius: 15,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    '& p:first-of-type': {
      marginTop: 0,
    },
    '& p:last-of-type': {
      marginBottom: 0,
    },
    '&:focus-visible': {
      outline: 'none',
      boxShadow: '0 0 0 3px #4ab2bc',
    },
  },
  userBubble: {
    backgroundColor: '#528bbc', // #1976d2',
    color: '#fff',
    marginLeft: 'auto',
    borderBottomRightRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  aiBubble: {
    backgroundColor: '#e0e0e0',
    color: '#000',
    marginRight: 'auto',
    borderBottomLeftRadius: 0,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    position: 'absolute',
    transform: (props: ChatBubbleProps) => (props.isUser ? 'translateY(-80%) translateX(90%)' : 'translateY(-95%) translateX(-100%)'), // Position based on user
  },
}));

interface ChatBubbleProps {
  message: string;
  isUser: boolean;
}

export default function ChatBubble(props: ChatBubbleProps) {
  const { message, isUser } = props;
  const classes = useStyles(props);
  const userProfile = useUserProfile();

  return (
    <Box className={`${classes.bubbleContainer} ${isUser ? 'userBubble' : ''}`}>
      <Box className={`${classes.bubble} ${isUser ? classes.userBubble : classes.aiBubble}`} tabIndex={0}>
        <Box className={classes.avatar}>
          {isUser ? (
            <Tooltip title={getUserName(userProfile)}>
              <Avatar
                alt={getUserName(userProfile)}
                src={userProfile.imageUrl}
                sx={{ width: 32, height: 32 }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Pathfinder">
              <img
                src={`${reactPathTS}Pathfinder-face-teal.svg`}
                alt="Pathfinder"
                width={32}
                height={32}
              />
            </Tooltip>
          )}
        </Box>
        {isUser && <Typography variant="body1">{message}</Typography>}
        {!isUser && <ReactMarkdown>{message}</ReactMarkdown>}
      </Box>
    </Box>
  );
}
